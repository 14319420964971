var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.authenticated)?_c('NotPermission'):_c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[[_c('Breadcrumbs',{attrs:{"routes":[{ name: 'Inicio' }, { name: 'Nueva Etiqueta' }]}}),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md12":"","sm12":"","xs12":""}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('span',{staticClass:"success--text font-weight-bold headline"},[_vm._v("Crear etiqueta")])]),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitCreateEtiqueta.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md12":"","sm12":"","xs12":""}},[_c('v-autocomplete',{attrs:{"items":_vm.tipos,"dense":"","outline":"","clearable":"","small-chips":"","label":"Tipo","rules":_vm.rules.tipo,"error":!!_vm.formErrors.tipo,"error-messages":_vm.formErrors.tipo},on:{"keyup":function () {
                          _vm.formErrors.tipo = undefined;
                          delete _vm.formErrors.tipo;
                        }},model:{value:(_vm.form.tipo),callback:function ($$v) {_vm.$set(_vm.form, "tipo", $$v)},expression:"form.tipo"}})],1),_c('v-flex',{attrs:{"md12":"","sm12":"","xs12":""}},[(_vm.form.tipo === 'FILTROS')?_c('v-autocomplete',{attrs:{"items":_vm.filtros,"dense":"","outline":"","clearable":"","small-chips":"","label":"Seleccione el filtro","rules":_vm.rules.titulo,"error":!!_vm.formErrors.titulo,"error-messages":_vm.formErrors.titulo},on:{"keyup":function () {
                          _vm.formErrors.titulo = undefined;
                          delete _vm.formErrors.titulo;
                        }},model:{value:(_vm.form.titulo),callback:function ($$v) {_vm.$set(_vm.form, "titulo", $$v)},expression:"form.titulo"}}):_vm._e()],1)],1),_c('v-text-field',{attrs:{"disabled":_vm.processingForm,"label":"Nombre","outline":"","rules":_vm.rules.nombre,"error":!!_vm.formErrors.nombre,"error-messages":_vm.formErrors.nombre},on:{"keyup":function () {
                      _vm.formErrors.nombre = undefined;
                      delete _vm.formErrors.nombre;
                    }},model:{value:(_vm.form.nombre),callback:function ($$v) {_vm.$set(_vm.form, "nombre", $$v)},expression:"form.nombre"}})],1),_c('v-divider',{staticClass:"mb-3"}),_c('div',{staticClass:"text-xs-center pb-3"},[_c('v-btn',{attrs:{"type":"submit","color":"success","disabled":!_vm.validForm || _vm.processingForm,"loading":_vm.processingForm}},[_vm._v("Guardar")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.$router.push({ name: 'ListaEtiqueta' })}}},[_vm._v("Cancelar")])],1)],1)],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }